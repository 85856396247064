







import Vue from "vue";

import Component from "vue-class-component";

@Component({
  name: "IconClimbing",
})
export default class IconClimbing extends Vue {
}

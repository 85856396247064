







import Vue from "vue";

import Component from "vue-class-component";

@Component({
  name: "IconSkiing",
})
export default class IconSkiing extends Vue {
}

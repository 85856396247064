







import Vue from "vue";

import Component from "vue-class-component";

@Component({
  name: "IconWindsurfing",
})
export default class IconWindsurfing extends Vue {
}

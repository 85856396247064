







import Vue from "vue";

import Component from "vue-class-component";

@Component({
  name: "IconCrossCountrySkiing",
})
export default class IconCrossCountrySkiing extends Vue {
}

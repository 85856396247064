







import Vue from "vue";

import Component from "vue-class-component";

@Component({
  name: "IconTrackAndField",
})
export default class IconTrackAndField extends Vue {
}









import Vue from "vue";

import Component from "vue-class-component";

@Component({
  name: "IconBasketballPlayer",
})
export default class IconBasketballPlayer extends Vue {
}

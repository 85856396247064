







import Vue from "vue";

import Component from "vue-class-component";

@Component({
  name: "IconTableTennis",
})
export default class IconTableTennis extends Vue {
}

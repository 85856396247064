







import Vue from "vue";

import Component from "vue-class-component";

@Component({
  name: "IconDogPaw",
})
export default class IconDogPaw extends Vue {
}

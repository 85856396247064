<template>
  <div class="home">
    
    <div 
      v-if="($root.isMobile || $root.isTablet) && themeDone" 
      class="home__intro"
    >
      <div class="intro__background background--stopped">
        
        <div 
          class="background__image"
          :style="{
            'background-image': 'url(' + getImageSrc(getImageIndex()) + ')',
            'background-position': 'center',
            'background-size': 'cover',
            'background-repeat': 'no-repeat',
            'animation': 'none',
            'opacity': '1',
          }"
        >
        </div>
        
        <div class="intro--gradient">
          
          <div class="intro__content">
            
            <SearchFormFull
              :key_="$root.isMobile || $root.isTablet ? formKey : ''"
              :isBig="true"
              :focused="$root.isMobile || $root.isTablet ? formReset : false"
              @eventSelected="onEventSelected"
            />

            <div id="home__intro-search-form"></div>
          </div>

        </div>
      </div>
    </div>

    <div 
      v-if="(!$root.isMobile && !$root.isTablet) && themeDone"
      class="home__intro" 
    >
      <div class="intro__background" :class="getBackgroundClasses()">
        <div class="background__container">
          <div 
            v-for="(image, index) in themeImages"
            :key="index"
            :id="`image_${index}`"
            class="background__image background__image--large"
          >
            <div
              class="background__image-block"
              :style="getImageStyles(index)"
            ></div>
          </div>
        </div>

        <div v-if="searchShown" class="intro__overlay"></div>

        <div class="intro--gradient">
          
          <div class="intro__content">
            
            <SearchFormFull
              v-if="!isFormInvisible"
              :key_="!$root.isMobile && !$root.isTablet ? formKey : ''"
              :isBig="true"
              :focused="!$root.isMobile && !$root.isTablet ? formReset : false"
              @shown="onSearchShown"
              @eventSelected="onEventSelected"
            />
            
            <div id="home__intro-search-form"></div>
          </div>

        </div>
      </div>
    </div>

    <div 
      v-if="themeDone && (theme === '' || theme === 'MyEventPhoto') && isFormInvisible"
      class="home__header"
      :class="getHeaderClasses()"
    >
      <HomeHeader 
        :key_="homeFormKey"
        :formReset="formReset"
        @changed="onHeaderChanged"
      />
    </div>
    <div 
      v-if="!$root.isMobile && !$root.isTablet && theme === '' && isFormActive" 
      class="home__overlay" 
      @click.stop="homeFormKey = Math.random().toString()"
    >
    </div>

    <RecentEvents 
      v-if="themeDone"
      class="events"
    />

    <Features v-if="showFeatures" />
    <Features v-if="showCustomFeatures" :isCustom="true" />

    <div v-show="false">
      <form id="cameraForm" action="#" method="post" enctype="multipart/form-data">
        <input id="cameraInput" name="cameraInput" type="file" accept="image/*" />
      </form>
    </div>

  </div>
</template>


<script lang="js">
import { mapGetters } from 'vuex';
import SearchFormFull from "@/components/home/search-form-full.vue";
import RecentEvents from "@/components/home/recent-events.vue";
import HomeHeader from "@/components/home-header/home-header.vue";
import Features from "@/components/features/features.vue";

export default {
  name: 'Home',
  components: {
    SearchFormFull,
    RecentEvents,
    HomeHeader,
    Features,
  },
  data() {
    return {
      searchShown: false,
      isFormInvisible: false,
      isFormActive: false,
      formReset: false,
      formKey: '',
      homeFormKey: '',
      isFirstRun: true,
      activeIndex: 0,
      interval: null,
      intervalTime: 9000,
      animationPaused: false,
      showClass: 'background__image--show-animation',
      hideClass: 'background__image--hide-animation',
    };
  },
  computed: {
    ...mapGetters('SettingsState', ['theme', 'themeDone', 'lang', 'themeImages', 'isModalActive', 'isLoadingEvents', 'isMySportUSA']),
    ...mapGetters('CartState', ['cartId', 'isCartError']),
    isUSA() {
      return this.isMySportUSA === true && this.lang === 'en';
    },
    showFeatures() {
      return this.themeDone && this.theme === '' && !this.isLoadingEvents && !this.isMySportUSA;
    },
    showCustomFeatures() {
      return this.themeDone && this.theme === '' && !this.isLoadingEvents && this.isUSA;
    },
  },
  watch: {
    'themeImages.length'() {
      this.initSlider();
    },
    searchShown(value) {
      if (value) {
        this.animationPaused = true;
      } else if (!value && this.animationPaused) {
        this.resumeAnimation();
        this.animationPaused = false;
      }
    },
    themeDone() {
      this.updateFormInvisible();
    },
    isModalActive() {
      this.updateFormInvisible();
    },
  },
  methods: {
    getImageSrc(index) {
      if (index < 0) {
        return "";
      }
      if (!this.themeImages[index]) {
        return "";
      }
      if (index > this.themeImages.length-1) {
        index = 0;
      }

      if (this.$root.isMobile || this.$root.isTablet) {
        return this.themeImages[index].resources.mobile;
      } else {
        return this.themeImages[index].resources.desktop;
      }
    },
    getImageIndex() {
      const itemName = "introImageIndexMobile";
      const value = localStorage.getItem(itemName);
      let index = 0;
      if (value !== null && value !== "") {
        index = parseInt(value);
      }

      return index;
    },
    getImageStyles(index) {
      if (this.themeImages.length > 1) {
        return {
          'background-image': 'url(' + this.getImageSrc(index) + ')',
        }
      } else {
        return {
          'background-image': 'url(' + this.getImageSrc(index) + ')', 
          'animation': 'none',
          'opacity': '1',
        }
      }
    },
    initSlider() {
      this.saveImageIndex();
      this.showInitialSlideAndStart();
    },
    showInitialSlideAndStart() {
      if (this.$root.isMobile || this.$root.isTablet) return;

      setTimeout(() => {
        this.showSlide(this.activeIndex);
        this.startAnimation();
      }, 100);
    },
    startAnimation() {
      if (this.themeImages.length <= 1) return;
      if (this.$root.isMobile || this.$root.isTablet) return;
      if (this.interval) return;

      this.interval = setInterval(() => {
          if (this.searchShown || this.$route.name !== 'home') {
            clearInterval(this.interval);
            this.interval = null;
            return;
          }

          this.fadeToNext();
          this.activeIndex++;
          if (this.activeIndex >= this.themeImages.length) this.activeIndex = 0;
        }, this.intervalTime,
      );
    },
    resumeAnimation() {
      if (this.$route.name !== 'home') return;
      this.showSlide(this.activeIndex);

      const prev = this.getPrevIndex();
      const next = this.getNextIndex();
      this.getSlideImage(prev).classList.remove(this.showClass);
      this.getSlideImage(prev).classList.remove(this.hideClass);
      this.getSlideImage(this.activeIndex).classList.remove(this.showClass);
      this.getSlideImage(this.activeIndex).classList.remove(this.hideClass);
      this.getSlideImage(next).classList.remove(this.hideClass);
      this.getSlideImage(next).classList.remove(this.showClass);

      this.startAnimation();
    },
    fadeToNext() {
      if (this.$route.name !== 'home') return;
      const prev = this.getPrevIndex();
      const next = this.getNextIndex();

      this.showSlide(0, false);
      this.showSlide(prev, false);
      this.showSlide(this.activeIndex, false);
      this.showSlide(next, false);
      this.getSlideImage(prev).classList.remove(this.showClass);
      this.getSlideImage(prev).classList.remove(this.hideClass);
      this.getSlideImage(this.activeIndex).classList.remove(this.showClass);
      this.getSlideImage(this.activeIndex).classList.add(this.hideClass);
      this.getSlideImage(next).classList.remove(this.hideClass);
      this.getSlideImage(next).classList.add(this.showClass);
    },
    showSlide(index, show = true) {
      this.getSlideImage(index).style.opacity = show ? '1' : '';
      this.getSlideImage(index).style.transform = show ? 'scale(1.1)' : '';
    },
    getSlideImage(index) {
      const image = `image_${index}`;
      return document.getElementById(image);
    },
    getPrevIndex() {
      let prev = this.activeIndex - 1;
      if (prev < 0) prev = this.themeImages.length - 1;
      return prev;
    },
    getNextIndex() {
      let next = this.activeIndex + 1;
      if (this.activeIndex === this.themeImages.length - 1) next = 0;
      return next;
    },
    getBackgroundClasses() {
      const classes = [];
      if (this.searchShown && this.themeImages.length > 1) {
        classes.push('background--stopped');
      }
      return classes;
    },
    getHeaderClasses() {
      const classes = [];
      
      if (!this.$root.isMobile && !this.$root.isTablet) {
        classes.push("home__header--large");
      }
      if (this.isFormActive && !this.$root.isMobile) {
        classes.push('home__header--active');
      }
      
      return classes;
    },
    saveImageIndex() {
      const itemName = "introImageIndexMobile";
      
      let index = this.getImageIndex() + 1;
      if (index > this.themeImages.length-1) {
        index = 0;
      }
      
      localStorage.setItem(itemName, index.toString());
    },
    onSearchShown(shown) {
      this.searchShown = shown;
    },
    onEventSelected(value) {
      this.reachGoal("media_event_selected");
      this.reachGoal("event_selected_from_search");

      this.$router.push({ 
        name: "event",
        params: { 
          id: value.externalEventId,
        } 
      });
    },
    async onHeaderChanged(active) {
      this.isFormActive = active;
      if (active && (this.$root.isMobile || this.$root.isTablet)) {
        this.formReset = true;
        this.formKey = Math.random().toString();
      }
      await this.updateFormInvisible();
    },
    async updateFormInvisible() {
      if (this.$route.name && this.$route.name !== 'home') {
        await this.setAppBarHidden(false);
        window.removeEventListener('scroll', async () => { await this.updateFormInvisible(); });
        return;
      }

      const form = document.getElementById('home__intro-search-form');
      if (!form) return;

      const bottom = form.getBoundingClientRect().bottom;
      let invisible = this.isFormActive ? bottom <= 180 : bottom <= 64;

      if (this.isModalActive && (this.$root.isMobile || this.$root.isTablet)) {
        invisible = false;
      }
      
      this.isFormInvisible = invisible;
      const themesWithScroll = ['', 'MyEventPhoto'];
      if (themesWithScroll.includes(this.theme)) await this.setAppBarHidden(invisible);

      if (this.$root.isMobile || this.$root.isTablet) {
        this.isFirstRun = false;
        return;
      }
      
      if (!invisible && !this.isFirstRun) {
        this.formReset = true;
        this.homeFormKey = Math.random().toString();
        this.isFormActive = false;
      } else {
        this.formReset = false;
      }
      this.isFirstRun = false;
    },
    async setAppBarHidden(payload) {
      await this.$store.dispatch('SettingsState/setAppBarHidden', payload);
    },
    async setMyPhotosLink(payload) {
      await this.$store.dispatch('SettingsState/setMyPhotosLink', payload);
    },
    async trackPage(payload) {
      await this.$store.dispatch('SettingsState/trackPage', payload);
    },
    async reachGoal(payload) {
      await this.$store.dispatch('SettingsState/reachGoal', payload);
    },
    async loadCartId() {
      await this.$store.dispatch('CartState/loadCartId');
    },
    async getCart(payload) {
      await this.$store.dispatch('CartState/getCart', payload);
    },
    async removeCart() {
      await this.$store.dispatch('CartState/removeCart');
    },
  },
  async mounted() {
    this.$store.state.buttonHome = true;
    this.$store.state.buttonBack = false;
    this.$store.state.buttonCart = true;
    this.$store.state.showMenu = true;

    window.scrollTo(0, 0);

    await this.loadCartId();

    if (this.cartId != "") { 
      await this.getCart({ cartId: this.cartId });
      
      if (this.isCartError) {
        await this.removeCart();
      }
    }

    this.$store.state.goBack = "";
    this.$store.state.goPage = "";

    await this.setMyPhotosLink({ link: "" });

    window.addEventListener('scroll', async () => { await this.updateFormInvisible(); });
    setTimeout(async () => { 
      await this.updateFormInvisible();
    }, 500);

    if (this.themeImages.length > 0 && this.themeDone) {
      this.initSlider();
    }

    if (this.$route.params.startSearch === "true") {
      setTimeout(() => { 
        this.formReset = true;
        this.formKey = Math.random().toString();
      }, 750);
    }

    this.trackPage("/");
  },
}
</script>


<style lang="scss" scoped>
  @import "./home";
</style>

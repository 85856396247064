







import Vue from "vue";

import Component from "vue-class-component";

@Component({
  name: "IconRunning",
})
export default class IconRunning extends Vue {
}

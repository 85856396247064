







import Vue from "vue";

import Component from "vue-class-component";

@Component({
  name: "IconSwimming",
})
export default class IconSwimming extends Vue {
}

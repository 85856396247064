







import Vue from "vue";

import Component from "vue-class-component";

@Component({
  name: "IconGymnastics",
})
export default class IconGymnastics extends Vue {
}









import Vue from "vue";

import Component from "vue-class-component";

@Component({
  name: "IconTennisPlayer",
})
export default class IconTennisPlayer extends Vue {
}

<template>
  <div class="home-header">

    <Button 
      v-if="$root.isMobile"
      @click="onMenu()" 
      variant="clear"
      view="light"
      shape="circle"
      size="md"
      class="home-header__menu-button"
    >
      <slot name="before">
        <span class="rr-button__icon rr-button__icon_auto">
          <base-icon width="20" height="20" viewBox="0 0 24 24">
            <icon-menu2 />
          </base-icon>
        </span>
      </slot>
    </Button>

    <div 
      v-if="!$root.isMobile"
      class="home-header__logo" 
      :style="{ 'background-image': `url('${logo}')` }"
      @click.stop="onHome()"
    ></div>

    <div 
      v-if="!active || ($root.isMobile || $root.isTablet)" 
      class="home-header__search" 
      :class="!$root.isMobile && !$root.isTablet ? 'home-header__search--large' : ''"
      @click.stop="onSearch()"
    >
      <div class="search__text">
        {{ $t('homeHeader/startSearch') }}
      </div>
      <div class="search__icon">
        <base-icon v-if="theme === ''" width="18" height="18" viewBox="0 0 24 24">
          <icon-search2 fill="var(--color-white)" opacity="1" />
        </base-icon>
        <base-icon v-if="theme !== ''" width="18" height="18">
          <icon-search fill="var(--color-white)" opacity="1" />
        </base-icon>
      </div>
    </div>

    <div v-if="false && active && !$root.isMobile">
      <div class="home-header__menu">
        <div
          v-for="item in menuMain"
          :key="item.id"
          class="menu__item"
          :class="!isItemVisible(item) ? 'menu__item--hidden' : ''"
        >
          <a :href="item.href" class="item__link" :class="{ 'item__link--selected': item.id == menuSelected }">
            {{ $t(item.text) }}
          </a>
        </div>
        <div
          class="menu__item"
        >
          <a href="#" @click.prevent class="item__additional">
            <span>{{ $t('appBar/menuMore') }}</span>
            <base-icon v-if="theme === ''" width="16" height="16" viewBox="0 0 24 24" class="additional__icon">
              <icon-down2 fill="var(--color-black)" opacity="1" />
            </base-icon>
            <base-icon v-if="theme !== ''" width="16" height="16" class="additional__icon">
              <icon-down fill="var(--color-black)" opacity="1" />
            </base-icon>
            <div class="additional__menu-container">
              <div class="additional__menu" id="app-bar__additional__menu">
                <div class="additional__menu-item" @click.prevent="onAdditionalClick()">
                  {{ $t('appBar/menuReportError') }}
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>

    <div v-if="active && !$root.isMobile && !$root.isTablet">
      <div class="home-header__form">
        <search-form-full 
          class="form__field"
          :class="searchShown ? 'form__field--shown' : ''"
          :focused="active"
          :key_="searchFormKey"
          @shown="onSearchShown"
          @eventSelected="onEventSelected"
        >
        </search-form-full>
      </div>
    </div>

    <div class="home-header__icons" :class="!$root.isMobile && !$root.isTablet ? 'home-header__icons--large' : ''">
      <div v-if="$store.state.showLang && !$root.isMobile && !isMySportUSA && menuLang.length > 1" class="home-header__lang">
        <a href="#" @click.prevent="onLangClick()" @blur="onLangBlur()" class="lang__menu-item">
          <div class="menu-item__item">
            <base-icon width="18" height="18" class="menu-item__image">
              <icon-global />
            </base-icon>
          </div>
          
          <div v-if="!$root.isMobile" class="menu-item__submenu" :style="{'display': showLangDropdown ? 'block' : 'none'}">
            <div
              v-for="item in menuLang"
              :key="item.id"
              class="submenu__item"
              @click.prevent="onLangSelect(item.id)"
            >
              <div class="submenu__image">
                <img :src="'/img/lang/' + item.id + '.svg'" :alt="item.id" />
              </div>
              <div class="submenu__text" @click.prevent="onLangSelect(item.id)">
                {{ item.text }}
              </div>
            </div>
          </div>

          <div v-if="!$root.isMobile && !$root.isTablet" class="menu-item__submenu" v-show="showLangDropdown">
            <div
              v-for="item in menuLang"
              :key="item.id"
              class="submenu__item"
              @click.prevent="onLangSelect(item.id)"
            >
              <div class="submenu__image">
                <img :src="'/img/lang/' + item.id + '.svg'" :alt="item.id" />
              </div>
              <div class="submenu__text" @click.prevent="onLangSelect(item.id)">
                {{ item.text }}
              </div>
            </div>
          </div>
        </a>
      </div>

      <div v-show="!$root.isMobile && !$root.isTablet && keycloak && $store.state.buttonProfile" class="home-header__profile">
        <div
          v-show="keycloak && $store.state.buttonProfile"
          @click.stop="onProfile()"
          class="home-header__profile-icon"
        >
          <base-icon width="20" height="20" viewBox="0 0 24 24">
            <icon-user />
          </base-icon>
        </div>
      </div>

      <div v-show="$store.state.buttonCart" class="home-header__cart">
        <div
          v-show="$store.state.buttonCart"
          @click.stop="onCart()"
          class="home-header__cart-icon"
        >
          <base-icon v-if="theme === ''" width="20" height="20" viewBox="0 0 20 20">
            <icon-shopping-cart2 />
          </base-icon>
          <base-icon v-if="theme !== ''" width="20" height="20" viewBox="0 0 24 24">
            <icon-shopping-cart />
          </base-icon>
        </div>
        
        <div 
          class="cart__badge" 
          v-show="$store.state.buttonCart && itemsCount > 0"
          @click.stop="onCart()"
        >
          {{ itemsCount }}
        </div>
      </div>
    </div>

  </div>
</template>


<script lang="js">
import { mapGetters } from 'vuex';
import SearchFormFull from '@/components/home/search-form-full.vue';
import BaseIcon from '@/components/base-icon/base-icon.vue';
import IconShoppingCart from '@/components/icons/icon-shopping-cart.vue';
import IconShoppingCart2 from '@/components/icons/icon-shopping-cart2.vue';
import IconSearch from '@/components/icons/icon-search.vue';
import IconSearch2 from '@/components/icons/icon-search2.vue';
import IconDown from '@/components/icons/icon-down.vue';
import IconDown2 from '@/components/icons/icon-down2.vue';
import IconMenu2 from '@/components/icons/icon-menu2.vue';
import IconGlobal from '@/components/icons/icon-global.vue';
import IconUser from "@/components/icons/icon-user.vue";
import { MenuMain } from '@/menu-main';
import { MenuLang } from '@/menu-lang';

export default {
  name: 'HomeHeader',
  props: {
    key_: String,
    formReset: Boolean,
  },
  components: {
    SearchFormFull,
    BaseIcon,
    IconShoppingCart,
    IconShoppingCart2,
    IconSearch,
    IconSearch2,
    IconDown,
    IconDown2,
    IconMenu2,
    IconGlobal,
    IconUser,
  },
  data() {
    return {
      menuMain: MenuMain,
      menuSelected: 1,
      menuLang: MenuLang,
      showLangDropdown: false,
      active: false,
      searchShown: false,
      showAdditional: false,
      searchFormKey: '',
    };
  },
  computed: {
    ...mapGetters('SettingsState', ['theme', 'lang', 'languages', 'isMySportUSA', 'keycloak']),
    ...mapGetters('CartState', ['itemsCount']),
    key() {
      return this.$props.key_;
    },
    logo() {
      return this.$store.getters['SettingsState/logo'] || '/img/logo-media.svg';
    },
  },
  watch: {
    key() {
      this.active = false;
      this.searchShown = false;
      if (!this.$props.formReset) {
        this.$emit('changed', this.active);
      }
    },
  },
  methods: {
    onHome() {
      if (this.$route.name !== 'home') {
        this.$router.push({ name: 'home' });
      }
    },
    onMenu() {
      this.$store.dispatch('DrawerState/setShow', true);
    },
    onSearch() {
      this.active = true;

      setTimeout(() => {
          this.searchFormKey = 'searchForm' + Math.random().toString();
          this.$emit('changed', this.active);
        }, 
        100
      );
    },
    onLangClick() {
      const vm = this;
      setTimeout(() => { vm.showLangDropdown = !vm.showLangDropdown; return false; }, 250);
    },
    onLangBlur() {
      const vm = this;
      setTimeout(() => { vm.showLangDropdown = false; }, 250);
    },
    onLangSelect(lang) {
      if (!this.$root.isMobile && !this.$root.isTablet) {
        this.showLangDropdown = false;    
        setTimeout(() => { this.showLangDropdown = true; }, 100);
      }
      
      this.setLang(lang);
    },
    onAdditionalClick() {
      if (window.Userback) {
        window.Userback.open();
      }

      this.hideAdditionalMenu();
    },
    onProfile() {
      this.$router.replace({ path: '/my-account' });
    },
    hideAdditionalMenu() {
      const elem = document.getElementById('app-bar__additional__menu');
      if (elem === null) return;

      elem.classList.add('additional__menu--hidden');
      setTimeout(() => { elem.classList.remove('additional__menu--hidden'); }, 250);
    },
    isItemVisible(item) {
      if (item.href != '/' && item.href != '/contacts' && this.lang != 'ru') {
        return false;
      }
      return true;
    },
    getMenuSelected() {
      for (let i = 0; i < this.menuMain.length; i++) {
        if (location.pathname === this.menuMain[i].href) {
          return this.menuMain[i].id;
        }
      }
      return 1;
    },
    onSearchShown(shown) {
      this.searchShown = shown;
    },
    onEventSelected(value) {
      this.reachGoal('media_event_selected');
      this.reachGoal('event_selected_from_search');

      this.$router.push({ 
        name: 'event',
        params: { 
          id: value.externalEventId,
        } 
      });
    },
    onCart() {
      if (this.$route.name === 'cart') return;
      this.$router.push({ name: 'cart' });
    },
    setLang(payload) {
      this.$store.dispatch('SettingsState/setLang', payload);
    },
    reachGoal(payload) {
      this.$store.dispatch('SettingsState/reachGoal', payload);
    },
    setLanguagesAvailable() {
      const langs = this.languages;
      if (langs) {
        const result = [];
        for (let i = 0; i < MenuLang.length; i += 1) {
          if (langs.includes(MenuLang[i].id)) {
            result.push(MenuLang[i]);
          }
        }
        this.menuLang = result;
        return;
      }
      this.menuLang = MenuLang;
    },
  },
  mounted() {
    this.setLanguagesAvailable();
    this.menuSelected = this.getMenuSelected();
  },
}
</script>


<style lang="scss" scoped>
  @import "./home-header";
</style>

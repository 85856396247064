








import Vue from "vue";

import Component from "vue-class-component";

@Component({
  name: "IconTriathlon",
})
export default class IconTriathlon extends Vue {
}

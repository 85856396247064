








import Vue from "vue";

import Component from "vue-class-component";

@Component({
  name: "IconAquathlon",
})
export default class IconAquathlon extends Vue {
}









import Vue from "vue";

import Component from "vue-class-component";

@Component({
  name: "IconBiathlon",
})
export default class IconBiathlon extends Vue {
}

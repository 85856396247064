







import Vue from "vue";

import Component from "vue-class-component";

@Component({
  name: "IconCompass",
})
export default class IconCompass extends Vue {
}









import Vue from "vue";

import Component from "vue-class-component";

@Component({
  name: "IconBeachVolleyball",
})
export default class IconBeachVolleyball extends Vue {
}













































































import Vue from "vue";

import Component from "vue-class-component";

@Component({
  name: "FocusRafiki",
})
export default class FocusRafiki extends Vue {
}

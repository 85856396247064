










import Vue from "vue";

import Component from "vue-class-component";

@Component({
  name: "IconSteps",
})
export default class IconSteps extends Vue {
}










import Vue from "vue";

import Component from "vue-class-component";

@Component({
  name: "IconDuathlon",
})
export default class IconDuathlon extends Vue {
}

<template>
  <div class="banner">
    <a v-if="!emptyBanner" class="banner__link" :href="href ? href : `https://mkt.russiarunning.com/api/v1/${adspotId}/${randomKey}_${$root.isMobile ? 'mobile' : 'desktop'}.lnk`" :target="target || '_blank'" rel="noopener">
      <img 
        class="banner__image"
        :src="src ? src : `https://mkt.russiarunning.com/api/v1/${adspotId}/${randomKey}_${$root.isMobile ? 'mobile' : 'desktop'}.jpg`"
        alt=""
        @load="$emit('load')"
      >
    </a>
    <div v-if="emptyBanner" class="banner__link">
      <img class="banner__image" src="" alt="">
    </div>
  </div>
</template>


<script lang="js">
const { v4: uuidv4 } = require('uuid');

export default {
  name: 'Banner',
  props: {
    adspotId: String,
    src: String,
    href: String,
    target: String,
    emptyBanner: Boolean,
  },
  data() {
    return {
      randomKey: '',
    };
  },
  created() {
    this.randomKey = uuidv4();
  },
};
</script>


<style lang="scss" scoped>
  @import "./banner";
</style>












































































































































































































































import Vue from "vue";

import Component from "vue-class-component";

@Component({
  name: "BusinessBecisionsRafiki",
})
export default class BusinessBecisionsRafiki extends Vue {
}
